<template>
  <div class="platformServices">
    <div class="title">COMPAX DMS</div>
    <div class="text">
      Developed an integrated end-to-cloud commercial device management system
      tailored for partners, offering professional, efficient, simple, secure,
      and controllable device and content management capabilities.
    </div>
    <a href="https://dms.compax.cc/#/" target="_blank" class="button"
      >Get Started</a
    >
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.platformServices {
  padding-top: 52px;
  height: 1000px;
  background: url('../../../assets/PlatformServices/无标题2.png') no-repeat
    center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: rgba(255, 255, 255, 1);
    font-size: 60px;
    font-weight: 700;
    line-height: 86.88px;
    margin-bottom: 17px;
  }
  .text {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 20px;
    line-height: 28.96px;
    width: 815px;
    text-align: justify;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin-top: 31px;
    border-radius: 20px;
    background: rgba(0, 92, 255, 1);
    width: 200px;
    height: 50px;
  }
}
</style>
